<template>
  <div>
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">
          投诉建议
        </span>
      </div>
      <img style="width: 100%; height: 100%"
           src="../../assets/img/title_1.jpg"
           alt="" />
    </div>
    <div class="login_box_right">
      <div class="sub_box">
        <p class="complaint_advice_title">请在线提交您的留言，我们将尽快联系您！</p>
      </div>
      <el-form :model="ruleForm"
               status-icon
               :rules="rules"
               ref="ruleForm"
               :label-position="labelPosition"
               label-width="110px"
               class="demo-ruleForm">
        <el-form-item prop="name"
                      label="姓名"
                      class="login_form_item">
          <el-input v-model="ruleForm.name"
                    style="width:555px"></el-input>
        </el-form-item>
        <el-form-item prop="mobile"
                      label="手机号码"
                      class="login_form_item">
          <el-input v-model.number="ruleForm.mobile"
                    style="width:555px"></el-input>
        </el-form-item>
        <el-form-item prop="email"
                      label="邮箱"
                      class="login_form_item">
          <el-input v-model="ruleForm.email"
                    style="width:555px"></el-input>
        </el-form-item>
        <el-form-item prop="content"
                      label="留言内容"
                      class="login_form_item">
          <el-input v-model="ruleForm.content"
                    type="textarea"
                    :rows="4"
                    style="width:555px"></el-input>
        </el-form-item>
      </el-form>
      <div class="sub_box">
        <div style="width:555px;margin-left:110px">
          <button class="sub1"
                  @click="handle_sub">提交</button>
          <button class="reset"
                  @click="handle_reset">重置</button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               class="my_dialog"
               width="529px"
               :show-close="false">
      <p class="my_dialog_title"><img style="width:65px;height:65px;position: relative;top: 18px;margin-right:10px;"
             src="@/assets/img/chenggong.png"
             alt="">已提交成功！</p>
      <p class="my_dialog_text">您的投诉建议已提交成功，我们会尽快处理。3S后回首页，或直接点击<span style="color:#C6271E;"
              @click="handle_return">回首页</span>！</p>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (!value) {
        return callback("请输入手机号");
      } else if (!reg.test(value)) {
        return callback("手机号格式不正确");
      }
      callback();
    };
    return {
      labelPosition: 'right',
      clearSetTimeout: null,
      dialogVisible: false,
      ruleForm: {
        mobile: "",
        email: '',
        content: '',
        name: '',
      },
      rules: {
        mobile: [{ validator: checkPhone, required: true, trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        content: [{ required: true, message: "请输入留言内容", trigger: "blur" }],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      },
    };
  },
  mounted () {
    this.labelPosition = window.screen.width < 1200 ? 'top' : 'right'
  },
  methods: {
    handle_return () {
      clearTimeout(this.clearSetTimeout);
      this.$router.push('/')
    },
    handle_reset () {
      this.ruleForm = {
        mobile: "",
        email: '',
        content: '',
        name: '',
      }
      this.$refs["ruleForm"].resetFields();
    },
    handle_sub () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "POST",
            data: this.qs.stringify({ ...this.ruleForm, source: '官网' }),
            url: "/api/mini/advise/save",
            headers: {
              'Authorization': this.cookies.get('token'),
            },
          }).then((res) => {
            if (res.data.status === 200) {
              this.dialogVisible = true;
              this.clearSetTimeout = setTimeout(() => {
                this.handle_return()
              }, 3000);
              clearInterval(this.timer);
            } else {
              this.$message({
                showClose: true,
                message: '请登录后提交',
                type: "error",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.complaint_advice_title {
  width: 555px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-bottom: 56px;
  margin-left: 110px;
}
.my_dialog_text {
  width: 366px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  line-height: 30px;
  margin: 0 auto;
  margin-top: 34px;
  text-align: center;
  margin-bottom: 43px;
  cursor: pointer;
}
.my_dialog_title {
  margin: 0 auto;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-top: 40px;
}
.get_code {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c11d14 !important;
  cursor: pointer;
  border: none;
  padding: 12px 0 !important;
}
.get_code:focus,
.get_code:hover {
  background: #fff !important;
}
.login_tab {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sub_box {
  width: 665px;
  margin: 44px auto 20px auto;
  text-align: center;
}
.sub1 {
  width: 150px;
  height: 50px;
  background: #c11d14;
  border: 1px solid #c11d14;
  border-radius: 3px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  cursor: pointer;
  margin-right: 32px;
}
.reset {
  width: 150px;
  height: 50px;
  background: #999999;
  border-radius: 3px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  border: 1px solid #999999;
  cursor: pointer;
}
.login_form_item {
  width: 665px;
  margin: 25px auto;
  position: relative;
}
.login_title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  text-align: center;
  margin: 68px 0 61px 0;
}
.login_box {
  width: 780px;
  height: 500px;
  display: flex;
}
.login_img {
  width: 340px;
  height: 500px;
}
.login_box_right {
  width: 1220px;
  /* height: 530px; */
  margin: 73px auto 0px auto;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
</style>
